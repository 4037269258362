body {
    font-family: Arial, sans-serif;
    background-color: #f4f4f4; /* Slightly darker light grey */
    margin: 0;
    padding: 0; /* Add some padding for consistent spacing */
}

h1 {
    color: #333; /* Dark grey for the main heading */
}

label {
    font-weight: bold;
    color: #333; /* Dark grey for labels */
}

select, input[type="text"], button {
    margin-top: 10px;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc; /* Light grey border */
    border-radius: 4px; /* Rounded corners */
}

button {
    background-color: #4a5568; /* Dark grey background for buttons */
    color: white;
    cursor: pointer;
}

button:hover {
    background-color: #2d3748; /* Darker grey on hover */
}

.message {
    margin-top: 15px;
    padding: 10px;
    background-color: #ffffff; /* Light grey background for messages */
    color: #333; /* Dark grey text for message content */
    border: 1px solid #ccc; /* Light grey border to define the message area */
    border-radius: 4px; /* Rounded corners for messages */
}

.footer {
    margin-top: 20px;
    text-align: center;
    color: #666; /* Medium grey for footer text */
}

button.disabled {
    background-color: #cccccc; /* Grey background for disabled state */
    color: #666; /* Grey text for disabled state */
    cursor: not-allowed; /* Change cursor to indicate it's not clickable */
}

/* Flex container to align select, input, and buttons horizontally */
.input-container {
    display: flex;
    align-items: center;
    gap: 10px; /* Space between elements */
    margin-bottom: 16px; /* Spacing below the input container */
}

/* Ensure the input field grows to fit the space */
input[type="text"] {
    flex-grow: 1; /* Allow input to grow relative to available space */
    min-width: 200px; /* Optional: Set a minimum width for better usability */
}

/* Flex container for buttons aligned on a new row */
.button-container {
    display: flex;
    justify-content: flex-end; /* Align buttons to the right */
    gap: 10px; /* Space between buttons */
}

/* Reduce the width of the select element */
select {
    width: 150px; /* Fixed width for select dropdown */
    background-color: #ffffff; /* White background for select */
    color: #333; /* Dark grey text for select */
}
