
.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh; /* Ensure full height for the layout */
  background-color: #f0f0f0; /* Light Grey background for the entire app */
}

main {
  flex: 1; /* Allow main content to grow */
  padding: 20px; /* Add some padding for spacing */
  color: black; /* Text color for the main content */
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
