/* src/components/flyerItems/FlyerItems.css */
.flyer-items {
    display: flex;
    flex-direction: column;
    gap: 15px; /* Space between items */
}

.item {
    border: 1px solid #ccc; /* Medium Grey border for items */
    padding: 10px;
    border-radius: 5px;
    background-color: #f9f9f9; /* Light grey background for items */
}

.item h2 {
    margin: 0;
    font-size: 1.2em;
    color: black; /* Black text for better readability */
}

.item p {
    margin: 5px 0 0;
    color: #555; /* Dark grey text for less emphasis */
}
