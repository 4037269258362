/* src/pages/Home.css */

.page-link {
  display: inline-block; /* Only the square area is clickable */
  text-decoration: none; /* Remove underline from link */
}

.page-square {
  width: 200px; /* Set width */
  max-width: 200px; /* Max width of 200px for larger screens */
  aspect-ratio: 1 / 1; /* Maintain a square aspect ratio */
  border: 2px solid #4a5568; /* Dark grey border color */
  border-radius: 8px; /* Rounded corners */
  overflow: hidden; /* Hide overflow */
  display: flex; /* Center image */
  align-items: center; /* Center vertically */
  justify-content: center; /* Center horizontally */
  margin: 20px; /* Space around the square */
  transition: transform 0.3s; /* Smooth transform effect */
  background-color: #f9f9f9; /* Light grey background for the square */
}

/* Media query for screens smaller than 768px (e.g., tablets, mobile) */
@media (max-width: 768px) {
  .page-square {
    max-width: 150px; /* Reduce max width on smaller screens */
    margin: 15px; /* Adjust margin for smaller screens */
  }
}

/* Media query for very small screens (e.g., phones) */
@media (max-width: 480px) {
  .page-square {
    max-width: 100px; /* Reduce size further for mobile */
    margin: 10px; /* Adjust margin for mobile */
  }
}

.page-square:hover {
  transform: scale(1.05); /* Scale up on hover */
}

.page-image {
  width: 100%; /* Make the image fill the square */
  height: 100%; /* Ensure the image covers the entire square */
  object-fit: cover; /* Maintain aspect ratio and cover the square */
  display: block; /* Remove any space below the image */
  border-radius: 8px; /* Match the square's rounded corners */
}
